














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SocialLinkModel } from "@/models/common";

@Component({
  name: "SocialLinksList",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: () => [] })
  public readonly links!: SocialLinkModel[];

  get socialLinks() {
    if (!this.links) return [];
    const socialLinks: unknown[] = [];

    this.links.forEach((x: SocialLinkModel) => {
      let icon = "";
      switch (x.socialName) {
        case "Instagram":
          icon = "instagram.svg";
          break;
        case "Facebook":
          icon = "fb.svg";
          break;
        case "Linkedin":
          icon = "linkedin.svg";
          break;
        case "Twitter":
          icon = "twitter.svg";
          break;
        case "GitHub":
          icon = "git.svg";
          break;
        case "Khan Academy":
          icon = "kh.svg";
          break;
        default:
          break;
      }

      if (icon) {
        socialLinks.push({
          url: x.socialUrl,
          icon: require(`@/assets/icons/social/${icon}`),
        });
      }
    });

    return socialLinks;
  }
}
