



































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  name: "CardMenu",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true })
  public readonly items!: unknown[];

  @Prop({ required: false, default: "mdi-dots-horizontal" })
  public readonly icon!: string;

  @Prop({ required: false, default: "$dark-grey-color" })
  public readonly iconColor!: string;

  @Emit()
  selected(item: unknown) {
    return item;
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
