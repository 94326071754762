








































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { extend, setInteractionMode, ValidationObserver } from "vee-validate";
import { ext, required } from "vee-validate/dist/rules";
import { MediaModel } from "@/models/common";
import { UploadMediaService } from "@/services/Public";
import { ALLOW_MEDIA_EXTENSIONS } from "@/constants/AllowMediaExtensions";
import TextField from "@/components/inputs/TextField/index.vue";
import FilePicker from "@/components/inputs/FilePicker/index.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: `This field is required.`,
});

extend("ext", {
  ...ext,
  message: `Only ${ALLOW_MEDIA_EXTENSIONS} files allowed`,
});

interface FormModel {
  title: string;
  description: string;
  file: File | null;
}

@Component({
  name: "UploadMedia",
  components: {
    ValidationObserver,
    TextField,
    FilePicker,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  private formData: FormModel = {
    title: "",
    description: "",
    file: null,
  };

  private loading = false;

  private ALLOW_MEDIA_EXTENSIONS = ALLOW_MEDIA_EXTENSIONS;

  @Watch("value")
  watchValue(value: boolean) {
    if (!value) {
      this.$refs.observer.reset();
      this.formData = {
        title: "",
        description: "",
        file: null,
      };
    }
  }

  async upload() {
    const formIsValid = await this.$refs.observer.validate();
    if (!this.formData.file || !formIsValid) return;

    try {
      this.loading = true;
      const response = await UploadMediaService.upload({
        title: this.formData.title,
        mediaFile: this.formData.file,
        description: this.formData.description,
        isMessage: true,
      });
      this.uploaded(response);
      this.input(false);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return value;
  }

  @Emit()
  uploaded(value: MediaModel) {
    return value;
  }
}
