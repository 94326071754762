


















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { MediaModel } from "@/models/common";
import PreviewMediaCard from "@/components/common/PreviewMediaCard/index.vue";

@Component({
  name: "SelectMediaDialog",
  components: { PreviewMediaCard },
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: false, default: () => [] })
  public readonly items!: MediaModel[];

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Emit()
  selected(value: MediaModel) {
    this.input(false);
    return value;
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
