





























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  name: "FilePicker",
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  @Prop({ required: false })
  public readonly value?: string | number;

  @Prop({ required: false })
  public readonly name?: string;

  @Prop({ required: false })
  public readonly rules?: string;

  @Prop({ required: false })
  public readonly vid?: string;

  @Prop({ required: false })
  public readonly hint?: string;

  @Prop({ required: false, default: "$file" })
  public readonly prependIcon!: string;

  @Prop({ required: false, default: "" })
  public readonly appendIcon!: string;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: false })
  public readonly disabled!: boolean;

  @Prop({ required: false, default: false })
  public readonly readonly!: boolean;

  @Prop({ required: false, default: false })
  public readonly hideDetails!: boolean;

  @Prop({ required: false, default: () => [] })
  public readonly errorMessages!: unknown[];

  @Prop({ required: false, type: Array, default: () => [] })
  public readonly accept!: string[];

  @Emit("click:append")
  clickAppend() {}

  @Emit()
  input(value: File | null) {
    return value;
  }

  @Emit()
  keypress() {}

  @Emit()
  keydown() {}

  @Emit()
  keyup() {}

  @Emit()
  mouseout() {}
}
