














































































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";

@Component({
  name: "RichTextEditor",
  components: {
    EditorContent,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: "" })
  public readonly value!: string;

  @Prop({ required: false, default: false })
  public readonly disabled!: boolean;

  private editor!: Editor;

  @Watch("value")
  watchValue(value: string) {
    let html = this.editor.getHTML();
    if (html !== this.value) {
      this.editor.commands.setContent(value);
    }
  }

  @Watch("disabled")
  watchDisabled(value: boolean) {
    this.editor.setOptions({ editable: !value });
  }

  beforeMount() {
    this.editor = new Editor({
      content: this.value,
      editable: !this.disabled,
      onUpdate: ({ editor }) => this.input(editor.getHTML()),
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false,
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
          defaultAlignment: "justify",
        }),
      ],
    });
  }

  beforeDestroy() {
    this.editor.destroy();
  }

  @Emit()
  input(value: string) {
    return value;
  }
}
