




import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  name: "PreviewImageCard",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly src!: string;

  @Emit()
  click() {}
}
