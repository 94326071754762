
































































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { MediaModel } from "@/models/common";
import RichTextEditor from "@/components/inputs/RichTextEditor/index.vue";
import SelectMediaDialog from "@/components/dialogs/SelectMediaDialog.vue";
import UploadMediaDialog from "@/components/dialogs/UploadMediaDialog.vue";
import PreviewMediaCard from "@/components/common/PreviewMediaCard/index.vue";

@Component({
  name: "NewMessageDialog",
  components: {
    RichTextEditor,
    SelectMediaDialog,
    UploadMediaDialog,
    PreviewMediaCard,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: "" })
  public readonly subject!: string;

  @Prop({ required: false, default: "" })
  private messageBody!: string;

  @Prop({ required: false, default: "" })
  public recipient!: string;

  @Prop({ required: false, default: null })
  public selectedMedia!: MediaModel | null;

  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: false })
  public readonly mediaLoading!: boolean;

  @Prop({ required: false, default: false })
  public readonly sending!: boolean;

  @Prop({ required: false, default: () => [] })
  public readonly recipients!: [];

  @Prop({ required: false, default: () => [] })
  public readonly mediaItems!: [];

  @Prop({ required: false, default: false })
  public readonly recipientsDisabled!: boolean;

  private isMobile = false;

  private showAttachDialog = false;

  private showUploadDialog = false;

  onResize() {
    this.isMobile = window.innerWidth <= 800;
  }

  @Emit("update:recipient")
  updateRecipient(value: string) {
    return value;
  }

  @Emit("update:subject")
  updateSubject(value: string) {
    return value;
  }

  @Emit("update:messageBody")
  updateMessageBody(value: string) {
    return value;
  }

  @Emit("update:selectedMedia")
  updateSelectedMedia(value: MediaModel | null) {
    return value;
  }

  @Emit()
  input(value: boolean) {
    return value;
  }

  @Emit("openMedia")
  openMedia() {}

  @Emit()
  send() {}
}
