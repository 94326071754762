














import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { MediaModel } from "@/models/common";
import { EMediaType } from "@/models/enums";
import PreviewFileCard from "./PreviewFileCard.vue";
import PreviewImageCard from "./PreviewImageCard.vue";

@Component({
  name: "PreviewMediaCard",
  components: { PreviewFileCard, PreviewImageCard },
})
export default class extends Vue {
  @Prop({ required: true })
  public readonly value!: MediaModel;

  get isImage() {
    return (
      this.value.fileType === EMediaType.Image ||
      this.value.fileType === EMediaType.Video ||
      this.value.fileType === EMediaType.JobVideo
    );
  }

  get isFile() {
    return (
      this.value.fileType === EMediaType.Text ||
      this.value.fileType === EMediaType.Presentation ||
      this.value.fileType === EMediaType.MessageText ||
      this.value.fileType === EMediaType.MessagePresentation ||
      this.value.fileType === EMediaType.MessageVideo
    );
  }

  @Emit()
  click() {}
}
