







import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "LocationMarker",
  components: {},
})
export default class extends Vue {
  @Prop({ required: false, default: "" })
  public readonly location!: string;
}
