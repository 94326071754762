







import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import getFileIcon from "@/utils/getFileIcon";

@Component({
  name: "PreviewFileCard",
  components: {},
})
export default class extends Vue {
  @Prop({ required: false, default: "" })
  public readonly title!: string;

  @Prop({ required: false, default: "" })
  public readonly extension!: string;

  private getFileIcon = getFileIcon;

  @Emit()
  click() {}
}
