













import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "TextViewer",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly value!: string;

  @Prop({ required: false, default: 200 })
  public readonly textLength!: number;

  private readMoreEnabled = false;
}
