





















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  name: "ReportConfirmationDialog",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly candidateId!: string;

  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Emit()
  async onReportClick() {}

  @Emit()
  input(value: boolean) {
    return value;
  }
}
